<template>
  <v-container class="ma-0" fluid>
    <layout :back="true" />
    <v-row class="mx-1" justify-sm="center">
      <v-col cols="12" sm="8" md="4" class="ma-0">
        <v-text-field label="Notificaciones" hide-details> </v-text-field>
      </v-col>
    </v-row>
    <!-- <v-row class="pa-0 ma-0 mx-2" justify-sm="center">
      <v-col cols="5" sm="3" md="1" class="pa-0 align-center">
        <v-select
          solo
          :items="filters"
          v-model="combobox"
          hide-details
          dense
          class="my-2 text-body-2 primary--text rounded-lg"
          style="border: 0.5px solid #13454c"
        ></v-select
      ></v-col>
    </v-row> -->
    <v-row class="mx-2 pa-0 ma-0" justify-sm="center">
      <v-col cols="12" sm="8" md="4" class="ma-0 pa-0">
        <v-container class="ma-0 pa-0">
          <v-card
            class="cards mb-2"
            v-for="(notification, index) in notifications"
            :key="index"
          >
            <v-row class="ma-0 pa-0" full-width>
              <v-col cols="1" class="ma-0 pa-0 text-center mt-2"
                ><v-badge
                  v-if="notification.unread"
                  size="20"
                  color="secondary"
                  dot
                >
                </v-badge
              ></v-col>
              <v-col
                cols="8"
                class="ma-0 pa-0 font-weight-bold primary--text text-subtitle-1 mt-2"
                >{{ notification.verb }}</v-col
              >
              <v-col
                cols="3"
                class="ma-0 pa-0 secondary--text text-caption font-weight-light text-center mt-2 "
                >{{ 
                  notification.timestamp | formatTime }}</v-col
              >
            </v-row>
            <v-row class="ma-0"
              ><v-col
                cols="10"
                offset="1"
                class="pa-0 mb-2 font-weight-light secondary--text text-subtitle-2"
                >{{ notification.description }}</v-col
              ></v-row
            >
          </v-card>
        </v-container>
      </v-col>
    </v-row>
    <!-- <v-row class="mx-2 pa-0 ma-0" v-else justify-sm="center">
      <v-col cols="12" sm="8" md="4" class="ma-0 pa-0">
        <pharmacy-notifications />
      </v-col>
    </v-row> -->
  </v-container>
</template>
<script>
// Layout component
import layout from "@/components/layouts/Profile.vue";

// Offers cards components
// import CandidateNotifications from "@/components/notifications/CandidateNotifications.vue";
// import PharmacyNotifications from "@/components/notifications/PharmacyNotifications.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    // CandidateNotifications,
    // PharmacyNotifications,
    layout,
  },
  computed: {
    ...mapGetters({ userType: "user/type" }),
    ...mapGetters({ notifications: "core/notifications" }),
  },
  methods: {
    ...mapActions({ getNotifications: "core/getNotifications" }),
    ...mapActions({ readNotifications: "core/readNotifications" }),
    ...mapActions({
      markAllNotificationsAsRead: "core/markAllNotificationsAsRead",
    }),
  },
  mounted() {
    this.getNotifications();
  },
  data() {
    return {
      type: "candidate",
      filters: ["Recientes", "Anteriores"],
      combobox: null,
    };
  },
  async beforeRouteLeave(to, from, next) {
    this.markAllNotificationsAsRead();
    this.readNotifications();
    next();
  },
  // async beforeRouteLeave(){
  //   this.markAllNotificationsAsRead()
  // }
};
</script>
